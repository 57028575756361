import { template as template_38af237796394f9d9ce928696b295be8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_38af237796394f9d9ce928696b295be8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
