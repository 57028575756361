import { template as template_d639dbc6c1674afc8f661a05a9c288cd } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class MessagesSecondaryNav extends Component {
    get messagesNav() {
        return document.getElementById("user-navigation-secondary__horizontal-nav");
    }
    static{
        template_d639dbc6c1674afc8f661a05a9c288cd(`
    {{#in-element this.messagesNav}}
      {{yield}}
    {{/in-element}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
