import { template as template_e9308cf807e643cb83db8e0e5ea35f58 } from "@ember/template-compiler";
const FKText = template_e9308cf807e643cb83db8e0e5ea35f58(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
