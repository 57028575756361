import { template as template_4bc156b95fb14e1da581b0b5979ca16e } from "@ember/template-compiler";
const WelcomeHeader = template_4bc156b95fb14e1da581b0b5979ca16e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
